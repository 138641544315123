// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks'; // Importa useAuthContext
import { fcustomCurrency } from 'src/utils/format-number'; // Importa fcustomCurrency
import { Searchbar, AccountPopover, SettingsButton, NavUpgrade } from '../_common'; // Assicurati che il percorso sia corretto
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

type Props = {
    onOpenNav?: VoidFunction;
    pageTitle?: string;
    breadcrumbs?: React.ReactNode;
};

export default function Header({ onOpenNav, pageTitle, breadcrumbs }: Props) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const { user } = useAuthContext(); // Ottieni i dettagli dell'utente

    const isNavHorizontal = settings.themeLayout === 'horizontal';
    const isNavMini = settings.themeLayout === 'mini';
    const lgUp = useResponsive('up', 'lg');
    const offset = useOffSetTop(HEADER.H_DESKTOP); // Ripristina HEADER se necessario
    const offsetTop = offset && !isNavHorizontal;

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
            {!lgUp && (
                // <IconButton onClick={()=> onOpenNav?.()}>
                <IconButton onClick={() => {
                    console.log('Button clicked');
                    onOpenNav?.();
                }}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg"
                        sx={{
                            color: '#171C23'
                        }} />
                </IconButton>
            )}
            {/* <Searchbar /> */}
            {lgUp && (
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        color: theme.palette.text.primary,
                    }}
                >
                    {pageTitle} {/* Render the dynamic title here */}
                    {breadcrumbs && <div>{breadcrumbs}</div>}
                </Typography>
            )}
            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                {lgUp &&<NavUpgrade />}
                <Stack direction="row" alignItems="center" spacing={1} sx={{
                    bgcolor: '#171C23',
                    padding: '8px',
                    borderRadius: '5px'
                }}>
                    {/* <img src="/assets/icons/wallet.png" alt="Wallet Icon" style={{ width: 24, height: 24 }} /> */}


                    <IconButton sx={{
                        padding: "0px"
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="rgba(255,255,255,1)"><path d="M2.00488 8.99979H21.0049C21.5572 8.99979 22.0049 9.4475 22.0049 9.99979V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V8.99979ZM3.00488 2.99979H18.0049V6.99979H2.00488V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979ZM15.0049 13.9998V15.9998H18.0049V13.9998H15.0049Z" /></svg>
                    </IconButton>
                    <Typography variant="caption" component="div" sx={{
                        color: '#FFFFFF'
                    }}>
                        {fcustomCurrency(user?.balance)}
                    </Typography>
                </Stack>
                <Searchbar />
                <Stack direction="row" alignItems="center" spacing={1} sx={{
                    bgcolor: '#171C23',
                    padding: '8px',
                    borderRadius: '50px'
                }}>
                    <a href="https://betcedar.com" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="rgba(255,255,255,1)">
                            <path d="M20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20Z" />
                        </svg>
                    </a>
                </Stack>




                {/* <SettingsButton /> */}
                <AccountPopover />
                {/* <Stack>
                    <Stack>
                        <Typography variant="subtitle2" noWrap>
                            {user?.username}
                        </Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {user?.email}
                        </Typography>
                    </Stack>
                </Stack> */}
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE, // Assicurati che HEADER sia definito
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`, // Assicurati che NAV sia definito
                    height: HEADER.H_DESKTOP, // Assicurati che HEADER sia definito
                    ...(offsetTop && {
                        height: HEADER.H_DESKTOP_OFFSET // Assicurati che HEADER sia definito
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: 'background.default',
                        height: HEADER.H_DESKTOP_OFFSET, // Assicurati che HEADER sia definito
                        borderBottom: `dashed 1px ${theme.palette.divider}`
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 1}px)` // Assicurati che NAV sia definito
                    })
                })
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 }
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}
