import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
import { useAuthContext } from 'src/auth/hooks';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';

// ----------------------------------------------------------------------

type Props = {
    openNav: boolean;
    onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
    const { user } = useAuthContext();

    const pathname = usePathname();

    const lgUp = useResponsive('up', 'lg');

    const navData = useNavData();

    useEffect(() => {
        if (pathname && openNav) {
            console.log('NavVertical: Drawer should open');
            // Optional: Close the drawer when navigating to a new path
            // onCloseNav(); // Uncomment if needed
        }
    }, [pathname, openNav, onCloseNav]);

    console.log('NavVertical - openNav:', openNav);

    const renderContent = (
        <Scrollbar
            sx={{
                backgroundColor: '#171C23',
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            {/* <Logo sx={{ mt: 3, ml: 4, mb: 1 }} /> */}
            <Logo sx={{ mt: 2, ml: 3, mb: 0, mr:2 ,width:'127px',height:'40px'}} />

            <NavSectionVertical
                data={navData}
                config={{
                    currentRole: user?.role || 'admin'
                }}
            />

            <Box sx={{ flexGrow: 1 }} />

            {/* <NavUpgrade /> */}
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_VERTICAL }
            }}
        >
            {/* <NavToggleButton /> */}

            {lgUp ? (
                <Stack
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: NAV.W_VERTICAL,
                        borderRight: (theme) => `dashed 1px ${theme.palette.divider}`
                    }}
                >
                    {renderContent}
                </Stack>
            ) : (
                <Drawer
                    anchor="left"
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.W_VERTICAL
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
