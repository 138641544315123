// import { useMemo } from 'react';
// // routes
// import { paths } from 'src/routes/paths';
// // components
// import SvgColor from 'src/components/svg-color';
// import { useAuthContext } from 'src/auth/hooks';

// // ----------------------------------------------------------------------

// const icon = (name: string) => (
//     <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//     // OR
//     // <Iconify icon="fluent:mail-24-filled" />
//     // https://icon-sets.iconify.design/solar/
//     // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//     job: icon('ic_job'),
//     blog: icon('ic_blog'),
//     chat: icon('ic_chat'),
//     mail: icon('ic_mail'),
//     user: icon('ic_user'),
//     file: icon('ic_file'),
//     lock: icon('ic_lock'),
//     tour: icon('ic_tour'),
//     order: icon('ic_order'),
//     spueragent: icon('ic_settings-duotone'),
//     label: icon('ic_label'),
//     blank: icon('ic_blank'),
//     kanban: icon('ic_kanban'),
//     folder: icon('ic_folder'),
//     banking: icon('ic_banking'),
//     booking: icon('ic_booking'),
//     invoice: icon('ic_invoice'),
//     product: icon('ic_product'),
//     calendar: icon('ic_calendar'),
//     disabled: icon('ic_disabled'),
//     external: icon('ic_external'),
//     menuItem: icon('ic_menu_item'),
//     ecommerce: icon('ic_ecommerce'),
//     analytics: icon('ic_analytics'),
//     dashboard: icon('ic_dashboard'),
//     jackpot: icon('ic_jackpot')
// };


// // ----------------------------------------------------------------------

// const ADMIN_LIST = [
//     {
//         title: 'Game Management',
//         path: paths.games.index,
//         icon: ICONS.invoice,
//         children: [
//             { title: 'Providers', path: paths.games.provider },
//             { title: 'Games', path: paths.games.game }
//         ]
//     },
//     {
//         title: 'Operators',
//         path: paths.operator.list,
//         icon: ICONS.kanban,
//         // children: [
//         //     { title: 'List', path: paths.operator.list },
//         //     { title: 'Create', path: paths.operator.create }
//         // ]
//     },
//     // {
//     //     title: 'Super Agents',
//     //     path: paths.superAgent.index,
//     //     icon: ICONS.spueragent,
//     //     children: [
//     //         { title: 'List', path: paths.superAgent.list },
//     //         { title: 'Create', path: paths.superAgent.create }
//     //     ]
//     // },
//     {
//         title: 'Shops',
//         path: paths.shop.list,
//         icon: ICONS.order,
//         // children: [
//         //     { title: 'List', path: paths.shop.list },
//         //     { title: 'Create', path: paths.shop.create }
//         // ]
//     },
//     {
//         title: 'Players',
//         path: paths.user.index,
//         icon: ICONS.user
//     },
//     {
//         title: 'Bonus System',
//         path: paths.bonussystem.index,
//         icon: ICONS.label
//     },
//     {
//         title: 'Jackpot Management',
//         path: paths.jackpot.index,
//         icon: ICONS.jackpot,
//         children: [
//             { title: 'History', path: paths.jackpot.history },
//             { title: 'Settings', path: paths.jackpot.settings }
//         ]
//     },
//     {
//         title: 'Profile',
//         path: paths.profile.index,
//         icon: ICONS.job
//     },
//     {
//         title: 'Messages',
//         path: paths.message.index,
//         icon: ICONS.chat
//     }
// ];

// const SKYSPIN_LIST = [
//     {
//         title: 'Operators',
//         path: paths.operator.index,
//         icon: ICONS.kanban,
//         children: [
//             { title: 'List', path: paths.operator.list },
//             { title: 'Create', path: paths.operator.create }
//         ]
//     },
//     // {
//     //     title: 'Super Agents',
//     //     path: paths.superAgent.index,
//     //     icon: ICONS.spueragent,
//     //     children: [
//     //         { title: 'List', path: paths.superAgent.list },
//     //         { title: 'Create', path: paths.superAgent.create }
//     //     ]
//     // },
//     {
//         title: 'Shops',
//         path: paths.shop.index,
//         icon: ICONS.order,
//         children: [
//             { title: 'List', path: paths.shop.list },
//             { title: 'Create', path: paths.shop.create }
//         ]
//     },
//     {
//         title: 'Players',
//         path: paths.user.index,
//         icon: ICONS.user
//     },
//     {
//         title: 'Message',
//         path: paths.message.index,
//         icon: ICONS.chat
//     },
//     {
//         title: 'Profile',
//         path: paths.profile.index,
//         icon: ICONS.job
//     }
// ];

// const OPERATOR_LIST = [
//     // {
//     //     title: 'Super Agents',
//     //     path: paths.superAgent.index,
//     //     icon: ICONS.spueragent,
//     //     children: [
//     //         { title: 'List', path: paths.superAgent.list },
//     //         { title: 'Create', path: paths.superAgent.create }
//     //     ]
//     // },
//     {
//         title: 'Operators',
//         path: paths.operator.index,
//         icon: ICONS.kanban,
//         children: [
//             { title: 'List', path: paths.operator.list },
//             { title: 'Create', path: paths.operator.create }
//         ]
//     },
//     {
//         title: 'Shops',
//         path: paths.shop.index,
//         icon: ICONS.order,
//         children: [
//             { title: 'List', path: paths.shop.list },
//             { title: 'Create', path: paths.shop.create }
//         ]
//     },
//     {
//         title: 'Players',
//         path: paths.user.index,
//         icon: ICONS.user
//     },
//     {
//         title: 'Message',
//         path: paths.message.index,
//         icon: ICONS.chat
//     },
//     {
//         title: 'Profile',
//         path: paths.profile.index,
//         icon: ICONS.job
//     }
// ];

// const SUPER_AGENT_LIST = [
//     {
//         title: 'Shops',
//         path: paths.shop.index,
//         icon: ICONS.order,
//         children: [
//             { title: 'List', path: paths.shop.list },
//             { title: 'Create', path: paths.shop.create }
//         ]
//     },
//     {
//         title: 'Players',
//         path: paths.user.index,
//         icon: ICONS.user
//     },
//     {
//         title: 'Message',
//         path: paths.message.index,
//         icon: ICONS.chat
//     },
//     {
//         title: 'Profile',
//         path: paths.profile.index,
//         icon: ICONS.job
//     }
// ];

// const SHOP_LIST = [
//     {
//         title: 'Players',
//         path: paths.user.index,
//         icon: ICONS.user
//     },
//     {
//         title: 'Message',
//         path: paths.message.index,
//         icon: ICONS.chat
//     },
//     {
//         title: 'Profile',
//         path: paths.profile.index,
//         icon: ICONS.job
//     }
// ];

// // ----------------------------------------------------------------------

// export function useNavData() {
//     const { user } = useAuthContext();

//     const managementList = useMemo(() => {
//         if (user?.roleId === 'super_admin') {
//             return ADMIN_LIST;
//         }
//         if (user?.roleId === 'admin') {
//             if (user?.username === 'adminarabet') {
//                 return SKYSPIN_LIST;
//             }
//             return OPERATOR_LIST;
//         }
//         if (user?.roleId === 'super_agent') {
//             return SUPER_AGENT_LIST;
//         }
//         if (user?.roleId === 'shop') {
//             return SHOP_LIST;
//         }
//         return [];
//     }, [user]);

//     const data = useMemo(
//         () => [
//             {
//              items:[
//                 { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
//                  ]
//             },
//             {
//                 subheader: 'OVERVIEW',
//                 items: [
//                     { title: 'User Tree', path: paths.dashboard.usertree, icon: ICONS.ecommerce },
//                     { title: 'Operators', icon: ICONS.kanban },
//                     { title: 'Shops', icon: ICONS.order },
//                     { title: 'Players', icon: ICONS.user },
//                     { title: 'Messages', icon: ICONS.ecommerce },
//                 ]
//             },
//             {
//                 subheader: 'FINANCE',
//                 items: [
//                     { title: 'Casino Report', icon: ICONS.ecommerce },
//                     { title: 'Sport Report', icon: ICONS.ecommerce },
//                     { title: 'Transactions', icon: ICONS.ecommerce },
//                 ]
//             },
//             {
//                 subheader: 'MANAGEMENT',
//                 items: [
//                     { title: 'Providers', path: paths.games.provider },
//                     { title: 'Games', path: paths.games.game },
//                     { title: 'Network Logs'},
//                     {title: 'Profile',path: paths.profile.index,icon: ICONS.job}
//                 ]
//             },
//             {
//                 title: 'Jackpot Management',
//                 path: paths.jackpot.index,
//                 icon: ICONS.jackpot,
//                 children: [
//                     { title: 'History', path: paths.jackpot.history },
//                     { title: 'Settings', path: paths.jackpot.settings }
//                 ]
//             },
//         ],
//         [managementList]
//     );

//     return data;
// }



import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);

const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('transaction'),
    order: icon('ic_order'),
    spueragent: icon('ic_settings-duotone'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    jackpot: icon('ic_jackpot')
};

// ----------------------------------------------------------------------

const ADMIN_LIST = [
    {
        title: 'Providers Management',
        path: paths.games.provider,
        icon: ICONS.invoice,
        // children: [
        //   { title: 'Providers', path: paths.games.provider },
        //   { title: 'Games', path: paths.games.game }
        // ]
    },
    {
        title: 'Games Management',
        path: paths.games.game,
        icon: ICONS.invoice,
        // children: [
        //   { title: 'Providers', path: paths.games.provider },
        //   { title: 'Games', path: paths.games.game }
        // ]
    },
    {
        title: 'Network Logs',
        path: paths.dashboard.netlogs,
        icon: ICONS.lock
    },
    /* {
      title: 'Operators',
      path: paths.operator.list,
      icon: ICONS.kanban,
      children: [
          { title: 'List', path: paths.operator.list },
          { title: 'Create', path: paths.operator.create }
      ]
    }, */
    // {
    //     title: 'Super Agents',
    //     path: paths.superAgent.index,
    //     icon: ICONS.spueragent,
    //     children: [
    //         { title: 'List', path: paths.superAgent.list },
    //         { title: 'Create', path: paths.superAgent.create }
    //     ]
    // },
    /* {
      title: 'Shops',
      path: paths.shop.list,
      icon: ICONS.order,
      // children: [
      //     { title: 'List', path: paths.shop.list },
      //     { title: 'Create', path: paths.shop.create }
      // ]
    },
    {
      title: 'Players',
      path: paths.user.index,
      icon: ICONS.user
    }, */
    {
        title: 'Profile',
        path: paths.profile.index,
        icon: ICONS.job
    },
    {
        title: 'Bonus System',
        path: paths.bonussystem.index,
        icon: ICONS.label
    },
    {
        title: 'Jackpot Management',
        path: paths.jackpot.index,
        icon: ICONS.jackpot,
        children: [
            { title: 'History', path: paths.jackpot.history },
            { title: 'Settings', path: paths.jackpot.settings }
        ]
    },
    /* {
      title: 'Messages',
      path: paths.message.index,
      icon: ICONS.chat
    } */
];

const SKYSPIN_LIST = [
    {
        title: 'Operators',
        path: paths.operator.index,
        icon: ICONS.kanban,
        children: [
            { title: 'List', path: paths.operator.list },
            { title: 'Create', path: paths.operator.create }
        ]
    },
    // {
    //     title: 'Super Agents',
    //     path: paths.superAgent.index,
    //     icon: ICONS.spueragent,
    //     children: [
    //         { title: 'List', path: paths.superAgent.list },
    //         { title: 'Create', path: paths.superAgent.create }
    //     ]
    // },
    {
        title: 'Shops',
        path: paths.shop.index,
        icon: ICONS.order,
        children: [
            { title: 'List', path: paths.shop.list },
            { title: 'Create', path: paths.shop.create }
        ]
    },
    {
        title: 'Players',
        path: paths.user.index,
        icon: ICONS.user
    },
    {
        title: 'Message',
        path: paths.message.index,
        icon: ICONS.chat
    },
    {
        title: 'Profile',
        path: paths.profile.index,
        icon: ICONS.job
    }
];

const OPERATOR_LIST = [
    // {
    //     title: 'Super Agents',
    //     path: paths.superAgent.index,
    //     icon: ICONS.spueragent,
    //     children: [
    //         { title: 'List', path: paths.superAgent.list },
    //         { title: 'Create', path: paths.superAgent.create }
    //     ]
    // },
    // {
    //     title: 'Operators',
    //     path: paths.operator.index,
    //     icon: ICONS.kanban,
    //     children: [
    //         { title: 'List', path: paths.operator.list },
    //         { title: 'Create', path: paths.operator.create }
    //     ]
    // },
    // {
    //     title: 'Shops',
    //     path: paths.shop.index,
    //     icon: ICONS.order,
    //     children: [
    //         { title: 'List', path: paths.shop.list },
    //         { title: 'Create', path: paths.shop.create }
    //     ]
    // },
    // {
    //     title: 'Players',
    //     path: paths.user.index,
    //     icon: ICONS.user
    // },
    // {
    //     title: 'Message',
    //     path: paths.message.index,
    //     icon: ICONS.chat
    // },
    {
        title: 'Network Logs',
        path: paths.dashboard.netlogs,
        icon: ICONS.lock
    },
    {
        title: 'Profile',
        path: paths.profile.index,
        icon: ICONS.job
    }
];

const SUPER_AGENT_LIST = [

    {
        title: 'Operators',
        path: paths.operator.index,
        icon: ICONS.kanban,
        children: [
            { title: 'List', path: paths.operator.list },
            { title: 'Create', path: paths.operator.create }
        ]
    },
    {
        title: 'Shops',
        path: paths.shop.index,
        icon: ICONS.order,
        // children: [
        //     { title: 'List', path: paths.shop.list },
        //     { title: 'Create', path: paths.shop.create }
        // ]
    },
    {
        title: 'Players',
        path: paths.user.index,
        icon: ICONS.user
    },
    {
        title: 'Message',
        path: paths.message.index,
        icon: ICONS.chat
    },
    {
        title: 'Profile',
        path: paths.profile.index,
        icon: ICONS.job
    }
];

const SHOP_LIST = [
    // {
    //     title: 'Players',
    //     path: paths.user.index,
    //     icon: ICONS.user
    // },
    // {
    //     title: 'Message',
    //     path: paths.message.index,
    //     icon: ICONS.chat
    // },
    {
        title: 'Network Logs',
        path: paths.dashboard.netlogs,
        icon: ICONS.lock
    },
    {
        title: 'Profile',
        path: paths.profile.index,
        icon: ICONS.job
    }
];

// ----------------------------------------------------------------------

export function useNavData() {
    const { user } = useAuthContext();

    const managementList = useMemo(() => {
        if (user?.roleId === 'super_admin') {
            return ADMIN_LIST;
        }
        if (user?.roleId === 'admin') {
            if (user?.username === 'adminarabet') {
                return SKYSPIN_LIST;
            }
            return OPERATOR_LIST;
        }
        if (user?.roleId === 'super_agent') {
            return SUPER_AGENT_LIST;
        }
        if (user?.roleId === 'shop') {
            return SHOP_LIST;
        }
        return [];
    }, [user]);

    const data = useMemo(
        () => [

            {
                subheader: 'overview',
                items: [
                    // { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
                    { title: 'User Tree', path: paths.dashboard.usertree, icon: ICONS.ecommerce },
                    // {
                    //     title: 'Operators',
                    //     path: paths.operator.list,
                    //     icon: ICONS.kanban
                    // },
                    // {
                    //     title: 'Shops',
                    //     path: paths.shop.list,
                    //     icon: ICONS.order,
                    // },

                    ...(user?.roleId === 'super_admin' ? [{ title: 'Operators', path: paths.admin.index, icon: ICONS.user }] : []),
                    // ...(user?.roleId !== 'shop' && user?.roleId !== 'super_agent' && user?.roleId !== 'city_manager' ? [{ title: 'City Managers', path: paths.operator.list, icon: ICONS.kanban }] : []),
                    // ...(user?.roleId !== 'shop' && user?.roleId !== 'super_agent' ? [{ title: 'Super Agents', path: paths.superAgent.index, icon: ICONS.spueragent }] : []),
                    ...(user?.roleId !== 'shop' ? [{ title: 'Shops', path: paths.shop.list, icon: ICONS.order }] : []),
                    {
                        title: 'Players',
                        path: paths.user.index,
                        icon: ICONS.user
                    },
                    // ...(user?.roleId !== 'shop' ? [{ title: 'Players', path: paths.user.index, icon: ICONS.user }] : []),


                    {
                        title: 'Messages',
                        path: paths.message.index,
                        icon: ICONS.chat
                    },
                    /* {
                      title: 'Casino Reports',
                      path: paths.dashboard.gamestatics,
                      icon: ICONS.analytics
                    },
                    {
                      title: 'Reports Sportbook',
                      path: paths.dashboard.sportsstatics,
                      icon: ICONS.external
                    },
                    {
                      title: 'Transactions',
                      path: paths.dashboard.transaction,
                      icon: ICONS.tour
                    },
                    {
                      title: 'Network Logs',
                      path: paths.dashboard.netlogs,
                      icon: ICONS.lock
                    } */
                ]
            },

            {
                subheader: 'Finance',
                items: [
                    {
                        title: 'Report',
                        path: paths.dashboard.gamestatics,
                        icon: ICONS.analytics
                    },
                    // {
                    //     title: 'Sport Report',
                    //     path: paths.dashboard.sportsstatics,
                    //     icon: ICONS.external
                    // },
                    {
                        title: 'Transactions',
                        path: paths.dashboard.transaction,
                        icon: ICONS.tour
                    }
                ]
            },

            // MANAGEMENT
            // ----------------------------------------------------------------------
            {
                subheader: 'management',
                items: managementList
            }
        ],
        [managementList, user?.roleId]
    );

    return data;
}
